/* eslint-disable import/no-anonymous-default-export */
import Home from "./Home";
import AboutUs from "./cms/aboutUs";
import ContactUs from "./cms/contactUs";
import PrivacyPolicy from "./cms/privacPolicy";
import TermsAndConditions from "./cms/terms&conditions";
import Faq from "./cms/faq's";
import Login from "./auth/asParent/login";
import OtpVerify from "./auth/asParent/otpVerify";
import ForgotPassword from "./auth/asParent/forgotPassword";
import ResetPassword from "./auth/asParent/resetPassword";
import Signup from "./auth/asParent/signup";
import ProfileSetup from "./auth/asParent/profile-setup";
import Congratulations from "./auth/asParent/congratulations";
import TutorLogin from "./auth/asTutor/login";
import TutorSignup from "./auth/asTutor/signup";
import TutorOtpVerify from "./auth/asTutor/otpVerify";
import TutorForgotPassword from "./auth/asTutor/forgotPassword";
import TutorResetPassword from "./auth/asTutor/resetPassword";
import TutorProfileSetup from "./auth/asTutor/profileSetup/step1-1";
import TutorBankDetail from "./auth/asTutor/profileSetup/step1-2";
import TutorTeachingDetail from "./auth/asTutor/profileSetup/step-2";
import TutorEducationBackground from "./auth/asTutor/profileSetup/step-3";
import TutorDocumentStatus from "./auth/asTutor/profileSetup/step-4";
import TutorExperience from "./auth/asTutor/profileSetup/step-5";
import ParentDashboard from "./parentDashboard/dashboard";
import ParentProfile from "./parentDashboard/profile";
import ParentWishlist from "./parentDashboard/Wishlist";
import ParentChat from "./parentDashboard/chat";
import ParentPairing from "./parentDashboard/pairing";
import ParentStudyMaterial from "./parentDashboard/studyMaterial";
import ParentAddress from "./parentDashboard/myAddress";
import ParentBookings from "./parentDashboard/myBookings";
import ParentBookingDetail from "./parentDashboard/bookingDetail";
import ParentEditBooking from "./parentDashboard/editBooking";
import ParentTutorDetail from "./parentDashboard/tutorDetail";
import ParentScheduleBooking from "./parentDashboard/scheduleBooking";
import ParentCheckout from "./parentDashboard/checkout";
import ParentPayment from "./parentDashboard/payment";
import TutorDashboard from "./tutorDashboard/dashboard";
import TutorBookingDetail from "./tutorDashboard/bookingDetail";
import TutorBookings from "./tutorDashboard/myBookings";
import TutorReviews from "./tutorDashboard/manageReviews";
import TutorEarnings from "./tutorDashboard/manageEarnings";
import TutorUsers from "./tutorDashboard/manageUsers";
import TutorChat from "./tutorDashboard/chat";
import TutorUserDetail from "./tutorDashboard/userDetail";
import ParentBookedTutor from "./parentDashboard/bookedTutor";
import ParentPopularTutor from "./parentDashboard/popularTutor";
import ParentRecomendedTutor from "./parentDashboard/recomendedTutor";
import ParentSearchResult from "./parentDashboard/searchResult";
import TutorProfile from "./tutorDashboard/profile";
import TutorProfileSetupEdit from "./auth/asTutor/editProfile/step1_edit";
import TutorBankDetailEdit from "./auth/asTutor/editProfile/step2_edit";
import TutorTeachingDetailEdit from "./auth/asTutor/editProfile/step3_edit";
import TutorEducationBackgroundEdit from "./auth/asTutor/editProfile/step4_edit";
import TutorDocumentStatusEdit from "./auth/asTutor/editProfile/step5_edit";
import TutorExperienceEdit from "./auth/asTutor/editProfile/step6_edit";
import RefundPolicy from "./cms/refundPolicy";
export default {
    Home,
    AboutUs,
    ContactUs,
    PrivacyPolicy,
    TermsAndConditions,
    Faq,
    RefundPolicy,
    // Parent
    Login,
    Signup,
    OtpVerify,
    ForgotPassword,
    ResetPassword,
    ProfileSetup,
    Congratulations,
    ParentDashboard,
    ParentProfile,
    ParentChat,
    ParentPairing,
    ParentStudyMaterial,
    ParentWishlist,
    ParentAddress,
    ParentBookings,
    ParentBookingDetail,
    ParentTutorDetail,
    ParentEditBooking,
    ParentScheduleBooking,
    ParentCheckout,
    ParentPayment,
    ParentBookedTutor,
    ParentPopularTutor,
    ParentRecomendedTutor,
    ParentSearchResult,

    // Tutor
    TutorLogin,
    TutorSignup,
    TutorOtpVerify,
    TutorForgotPassword,
    TutorResetPassword,
    TutorProfileSetup,
    TutorBankDetail,
    TutorTeachingDetail,
    TutorEducationBackground,
    TutorDocumentStatus,
    TutorExperience,
    TutorDashboard,
    TutorBookings,
    TutorBookingDetail,
    TutorReviews,
    TutorUsers,
    TutorUserDetail,
    TutorEarnings,
    TutorChat,
    TutorProfile,
    TutorProfileSetupEdit,
    TutorBankDetailEdit,
    TutorTeachingDetailEdit,
    TutorEducationBackgroundEdit,
    TutorDocumentStatusEdit,
    TutorExperienceEdit
};
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { ParentLayout } from "../../../layout/parentLayout";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  useAddWishListMutation,
  useGetWishListQuery,
} from "../../../service/wishListApi";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import useManageWishList from "../../../apiHooks/useManageWishlisht";
import { showToast } from "../../../constants/toast";
import { wishList } from "../../../types/General";
import { Box, Card, CardContent, Skeleton } from "@mui/material";
export default function ParentWishlist() {
  const navigate = useNavigate(); // hook for the navigation

  const { data, isError, isLoading, isSuccess } = useGetWishListQuery(); // api hook to fetch the wishlist listing
  const [addWishlist] = useAddWishListMutation();

  const handleWishList = async (item: wishList) => {
    console.log(item, "wish");
    try {
      let body = {
        tutorId: item?.tutorId,
      };

      const res = await addWishlist({ body }).unwrap();
      if (res?.statusCode === 200) {
        if (item?.isFav) {
          showToast("Tutor removed to wishlist");
        } else {
          showToast("Tutor added to wishlist");
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  return (
    <>
      <ParentLayout className="role-layout">
        <main className="content">
          <section className="uhb_spc pWishlist_sc">
            <div className="conta_iner v2">
              <div className="role_head">
                <button
                  className="back_arrow"
                  onClick={() => navigate("/parent/dashboard")}
                >
                  <img src={`/static/images/back.png`} alt="Back" />
                </button>
                <h1 className="hd_3">Wishlist</h1>
              </div>
              <div className="role_body">
                <div className="tutor_list gap_m">
                  {isLoading &&
                    Array.from(new Array(10))?.map((item, index) => (
                      <TutorCardSkeleton key={index} />
                    ))}
                  {isSuccess &&
                    data?.data?.data?.length ?
                    data?.data?.data.map((item, index) => (
                      <div className="tutor_item" key={item?.tutorId}>
                        <figure>
                          <img
                            src={
                              item?.tutors?.image && item?.tutors?.image !== ""
                                ? item?.tutors?.image
                                : "/static/images/card1.png"
                            }
                            alt="tutor image"
                          />
                          {item?.avgRating && (
                            <span className="t_rating">
                              <StarIcon /> {item?.avgRating?.toFixed(1) || ""}
                            </span>
                          )}
                          <span
                            className="t_fav"
                            onClick={() => {
                              handleWishList(item);
                            }}
                          >
                            {item?.isFav ? (
                              <FavoriteIcon />
                            ) : (
                              <FavoriteBorderIcon />
                            )}
                          </span>
                        </figure>
                        <div
                          className="tutor_info"
                          onClick={() =>
                            navigate(`/parent/tutor-detail/${item?._id}`)
                          }
                        >
                          <h2>{item?.tutors?.name || ""}</h2>
                          <p>
                            {item?.classCount
                              ? item.classCount > 99
                                ? "99+ classes"
                                : `${item.classCount} ${item.classCount > 1 ? "classes" : "class"}`
                              : ""}
                          </p>
                          {/* <p>{item?.?.join(" , ")}</p> */}
                          <ins>
                            ${item?.teachingdetails[0]?.price || ""}/hour
                          </ins>
                        </div>
                      </div>
                    )) : <div className="no_data">
                      <figure>
                        <img src="/static/images/noData.png" alt="no data found" />
                      </figure>
                      <p>Wishlist is empty</p>
                    </div>}
                </div>
              </div>
            </div>
          </section>
        </main>
      </ParentLayout>
    </>
  );
}

function TutorCardSkeleton() {
  return (
    <Card sx={{ display: "flex", borderRadius: 3, p: 1, width: "40%" }}>
      {/* Left Image Skeleton */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 1,
        }}
      >
        <Skeleton
          variant="rectangular"
          width={70}
          height={70}
          sx={{ borderRadius: 2 }}
        />
      </Box>

      {/* Right Content Skeleton */}
      <CardContent sx={{ width: "50%" }}>
        {/* Name Skeleton */}
        <Skeleton width="60%" height={30} sx={{ mb: 1 }} />

        {/* Classes Skeleton */}
        <Skeleton width="40%" height={20} sx={{ mb: 1 }} />

        {/* ID Skeleton */}
        <Skeleton width="80%" height={15} sx={{ mb: 1 }} />

        {/* Price Skeleton */}
        <Skeleton width="30%" height={25} />
      </CardContent>
    </Card>
  );
}

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

type props = {
    image: string,
    name: string,
    price: number,
    classCount: number,
    rating: number,
    subjects: string[],
}

const TutorCard = ({ image, name, price, classCount, rating, subjects }: props) => {
    const navigate = useNavigate();
    return (
        <div className="tutor_single">
            <figure>
                <img src={image} alt="img" />
            </figure>
            <div className="info">
                <p>{classCount}+ Classes</p>
                <p className="rating"><img src={`/static/images/frame1.png`} alt="img" /> {rating}</p>
            </div>
            <div className="body">
                <h3>{name}</h3>
                <p>
                    {subjects?.length ? (
                        subjects
                            .slice(0, 5) 
                            .map(item => item) 
                            .join(', ') 
                    ) : ""}...
                </p>
                <ins>${price}/hour</ins>
                <Button onClick={() => navigate("/auth/as-parent/login")}>Book a Class</Button>
            </div>
        </div>
    )
}

export default TutorCard;
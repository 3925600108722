/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { ParentLayout } from "../../../layout/parentLayout";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Card, Box, Skeleton } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useGetParentBookingQuery } from "../../../service/booking";
import clsx from "clsx";
import moment from "moment";
import Pagination from "../../../constants/Pagination";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ParentBookings() {
  const navigate = useNavigate();

  // STATES
  const [value, setValue] = React.useState(0); // state for the active value of the tab
  const [page, setPage] = useState<number>(1); // state to store the current page

  // API Hooks
  const { data, isLoading, isSuccess, isError } = useGetParentBookingQuery({
    tab: value === 0 ? 1 : value === 1 ? 3 : 2,
    page: page,
    limit: 12,
  }); // api for the fetching the data according to tab and page

  // implementing switch case for the different cases which is for the booking
  const statusCheck = (num: number): string => {
    switch (num) {
      case 1:
        return "Pending";
      case 2:
        return "Accepted";
      case 3:
        return "Completed";
      case 4:
        return "Rejected";
      case 5:
        return "Cancelled";
      case 6:
        return "Ongoing";
      case 7:
        return "Upcoming";
      default:
        return "Unknown";
    }
  };

  // onchange handler for the tab
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // onChange handler for the page
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil((data?.data?.totalBooking || 10) / 12); // calculating the total number of pages

  return (
    <>
      <ParentLayout className="role-layout">
        <main className="content">
          <section className="uhb_spc pBooking_sc">
            <div className="conta_iner v2">
              <div className="role_head">
                <button
                  className="back_arrow"
                  onClick={() => navigate("/parent/dashboard")}
                >
                  <img src={`/static/images/back.png`} alt="Back" />
                </button>
                <h1 className="hd_3">My Bookings</h1>
              </div>
              <div className="role_body">
                <Tabs
                  className="custom_tabs"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Upcoming" {...a11yProps(0)} />
                  <Tab label="Ongoing" {...a11yProps(1)} />
                  <Tab label="Past" {...a11yProps(2)} />
                </Tabs>
                <CustomTabPanel value={value} index={0}>
                  <div className="gap_m">
                    {isLoading &&
                      Array.from(new Array(9)).map((item, index) => (
                        <TutorCardSkeleton key={index} />
                      ))}
                    {isSuccess && data?.data?.booking?.length ? (
                      data?.data?.booking?.map((item, index) => (
                        <div
                          className={clsx("booking_item", {
                            accepted: item?.bookingStatus === 2,
                            pending: item?.bookingStatus === 1,
                            completed: item?.bookingStatus === 3,
                            cancelled:
                              item?.bookingStatus === 5 ||
                              item?.bookingStatus === 4,
                            ongoing: item?.bookingStatus === 6,
                          })}
                          onClick={() =>
                            navigate(
                              "/parent/booking-detail/accepted/" + item?._id
                            )
                          }
                          key={item?._id}
                        >
                          <ul className="top">
                            <li>
                              <span>Date :</span>
                              <strong>
                                {moment(item?.bookingdetails[0]?.date).format(
                                  "Do MMMM, YYYY"
                                ) || "-"}
                              </strong>
                            </li>
                            <li>
                              <span>Time :</span>
                              <strong>
                                {moment(
                                  item?.bookingdetails[0]?.startTime
                                ).format("hh:mmA")}{" "}
                                -{" "}
                                {moment(
                                  item?.bookingdetails[0]?.endTime
                                ).format("hh:mmA") || "-"}
                              </strong>
                            </li>
                          </ul>
                          <div className="infoBox">
                            <figure>
                              <img
                                src={
                                  item?.tutors?.image ||
                                  `/static/images/card3.png`
                                }
                                alt="Image"
                              />
                              <span>
                              {item?.tutors?.avgRating  && <StarIcon />}
                                {item?.tutors?.avgRating
                                  ? item.tutors.avgRating.toFixed(1)
                                  : ""}
                                {item?.tutors?.classCount &&
                                  `(${item.tutors.classCount})`}
                              </span>
                            </figure>
                            <h2>{item?.tutors?.name || ""}</h2>
                            <ul>
                              <li>
                                <span>Subject</span>
                                <strong>{item?.subjects[0]?.name || ""}</strong>
                              </li>
                            </ul>
                            <p className="status">
                              {statusCheck(item?.bookingStatus)}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no_data">
                        <figure>
                          <img
                            src="/static/images/noData.png"
                            alt="no data found"
                          />
                        </figure>
                        <p>No booking found</p>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div className="gap_m">
                    {isLoading &&
                      Array.from(new Array(9)).map((item, index) => (
                        <TutorCardSkeleton key={index} />
                      ))}
                    {isSuccess && data?.data?.booking?.length ? (
                      data?.data?.booking?.map((item, index) => (
                        <div
                          className={clsx("booking_item", {
                            accepted: item?.bookingStatus === 2,
                            pending: item?.bookingStatus === 1,
                            completed: item?.bookingStatus === 3,
                            cancelled:
                              item?.bookingStatus === 5 ||
                              item?.bookingStatus === 4,
                            ongoing: item?.bookingStatus === 6,
                          })}
                          onClick={() =>
                            navigate(
                              "/parent/booking-detail/accepted/" + item?._id
                            )
                          }
                          key={item?._id}
                        >
                          <ul className="top">
                            <li>
                              <span>Date :</span>
                              <strong>
                                {moment(item?.bookingdetails[0]?.date).format(
                                  "Do MMMM, YYYY"
                                ) || "-"}
                              </strong>
                            </li>
                            <li>
                              <span>Time :</span>
                              <strong>
                                {moment(
                                  item?.bookingdetails[0]?.startTime
                                ).format("hh:mmA")}{" "}
                                -{" "}
                                {moment(
                                  item?.bookingdetails[0]?.endTime
                                ).format("hh:mmA") || "-"}
                              </strong>
                            </li>
                          </ul>
                          <div className="infoBox">
                            <figure>
                              <img
                                src={
                                  item?.tutors?.image ||
                                  `/static/images/card3.png`
                                }
                                alt="Image"
                              />
                              <span>
                                {item?.tutors?.avgRating || item?.tutors?.avgRating !== null && <StarIcon />}
                                {item?.tutors?.avgRating
                                  ? item.tutors.avgRating.toFixed(1)
                                  : ""}
                                {item?.tutors?.classCount &&
                                  `(${item.tutors.classCount})`}
                              </span>
                            </figure>
                            <h2>{item?.tutors?.name || ""}</h2>
                            <ul>
                              <li>
                                <span>Subject</span>
                                <strong>{item?.subjects[0]?.name || ""}</strong>
                              </li>
                            </ul>
                            <p className="status">
                              {statusCheck(item?.bookingStatus)}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no_data">
                        <figure>
                          <img
                            src="/static/images/noData.png"
                            alt="no data found"
                          />
                        </figure>
                        <p>No booking found</p>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <div className="gap_m">
                    {isLoading &&
                      Array.from(new Array(9)).map((item, index) => (
                        <TutorCardSkeleton key={index} />
                      ))}
                    {isSuccess && data?.data?.booking?.length ? (
                      data?.data?.booking?.map((item, index) => (
                        <div
                          className={clsx("booking_item", {
                            accepted: item?.bookingStatus === 2,
                            pending: item?.bookingStatus === 1,
                            completed: item?.bookingStatus === 3,
                            cancelled:
                              item?.bookingStatus === 5 ||
                              item?.bookingStatus === 4,
                            ongoing: item?.bookingStatus === 6,
                          })}
                          onClick={() =>
                            navigate(
                              "/parent/booking-detail/accepted/" + item?._id
                            )
                          }
                          key={item?._id}
                        >
                          <ul className="top">
                            <li>
                              <span>Date :</span>
                              <strong>
                                {moment(item?.bookingdetails[0]?.date).format(
                                  "Do MMMM, YYYY"
                                ) || "-"}
                              </strong>
                            </li>
                            <li>
                              <span>Time :</span>
                              <strong>
                                {moment(
                                  item?.bookingdetails[0]?.startTime
                                ).format("hh:mmA")}{" "}
                                -{" "}
                                {moment(
                                  item?.bookingdetails[0]?.endTime
                                ).format("hh:mmA") || "-"}
                              </strong>
                            </li>
                          </ul>
                          <div className="infoBox">
                            <figure>
                              <img
                                src={
                                  item?.tutors?.image ||
                                  `/static/images/card3.png`
                                }
                                alt="Image"
                              />
                              <span>
                                <StarIcon />
                                {item?.tutors?.avgRating?.toFixed(1) || ""}
                                {item?.tutors?.classCount &&
                                  `(${item.tutors.classCount})`}
                              </span>
                            </figure>
                            <h2>{item?.tutors?.name || ""}</h2>
                            <ul>
                              <li>
                                <span>Subject</span>
                                <strong>{item?.subjects[0]?.name || ""}</strong>
                              </li>
                            </ul>
                            <p className="status">
                              {statusCheck(item?.bookingStatus)}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no_data">
                        <figure>
                          <img
                            src="/static/images/noData.png"
                            alt="no data found"
                          />
                        </figure>
                        <p>No booking found</p>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                {data?.data?.booking && data.data.booking.length > 0 && (
                  <Pagination
                    module={data.data.booking}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                  />
                )}
              </div>
            </div>
          </section>
        </main>
      </ParentLayout>
    </>
  );
}

const TutorCardSkeleton = () => {
  return (
    <Card
      sx={{
        display: "flex",
        padding: 2,
        borderRadius: "12px",
        backgroundColor: "#f0f4f8",
        width: "32%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Date and Time */}
          <Skeleton
            variant="rectangular"
            width={120}
            height={20}
            sx={{ borderRadius: "4px" }}
          />
          <Skeleton
            variant="rectangular"
            width={100}
            height={20}
            sx={{ borderRadius: "4px" }}
          />
        </Box>

        <Box sx={{ display: "flex", marginTop: 2 }}>
          {/* Image */}
          <Skeleton
            variant="rectangular"
            width={80}
            height={80}
            sx={{ borderRadius: "8px", marginRight: 2 }}
          />

          {/* Text Content */}
          <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="60%" height={25} />
            <Skeleton variant="text" width="40%" height={20} />
            <Skeleton variant="text" width="30%" height={20} />
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
          {/* Status Button */}
          <Skeleton
            variant="rectangular"
            width={90}
            height={30}
            sx={{ borderRadius: "15px" }}
          />
        </Box>
      </Box>
    </Card>
  );
};

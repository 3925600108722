/* eslint-disable jsx-a11y/img-redundant-alt */
import { ParentLayout } from "../../../layout/parentLayout";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FilterSidebar from "../common/filterSidebar";
import StarIcon from "@mui/icons-material/Star";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useGetPopularTutorListingQuery } from "../../../service/parentDashboard";
import { showError, showWarning } from "../../../constants/toast";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useState } from "react";
import Pagination from "../../../constants/Pagination";
import useManageWishList from "../../../apiHooks/useManageWishlisht";
export default function ParentRecomendedTutor() {
  const navigate = useNavigate(); // hook for the navigation
  const [page, setPage] = useState<number>(1);
  // API hooks
  const { data, isError, isSuccess, isLoading } =
    useGetPopularTutorListingQuery({
      limit: 20,
      page: page,
    });
    const {handleWishList}=useManageWishList()
  // onChange handler for the page
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil((data?.data?.totalTutor || 20) / 20); // calculating the total number of pages
  if (isError) {
    showError("Error occured");
  }

  return (
    <>
      <ParentLayout className="role-layout">
        <main className="content">
          <section className="uh_spc pTutor_sc">
            <div className="conta_iner v2">
              <div className="location_bar">
                <button
                  className="back_arrow"
                  onClick={() => navigate("/parent/dashboard")}
                >
                  <img src={`/static/images/back.png`} alt="Back" />
                </button>
                <figure>
                  <img src={`/static/images/address_icon.svg`} alt="Icon" />
                </figure>
                <h1>New York, United States</h1>
                <Box component="a">Change</Box>
              </div>
              <div className="parent_dash">
                <div className="gap_m">
                  <FilterSidebar />
                  <div className="rt_s">
                    <div className="dash_title">
                      <h2>Popular Tutors</h2>
                    </div>
                    <div className="tutor_list gap_m">
                      {isSuccess &&
                        data &&
                        data?.data?.tutor?.map((item, index) => (
                          <div className="tutor_item">
                            <figure>
                              <img
                                src={
                                  item?.image && item?.image !== ""
                                    ? item?.image
                                    : "/static/images/card1.png"
                                }
                                alt="tutor image"
                              />
                              {item?.avgRating && (
                                <span className="t_rating">
                                  <StarIcon />{" "}
                                  {item?.avgRating?.toFixed(1) || ""}
                                </span>
                              )}
                              <span className="t_fav" onClick={()=>{
                                handleWishList(item)
                              }}>
                                {item?.isFav ? (
                                  <FavoriteIcon />
                                ) : (
                                  <FavoriteBorderIcon />
                                )}
                              </span>
                            </figure>
                            <div
                              className="tutor_info"
                              onClick={() =>
                                navigate(`/parent/tutor-detail/${item?._id}`)
                              }
                            >
                              <h2>{item?.name || ""}</h2>
                              <p>
                                {item?.classCount
                                  ? item.classCount > 99
                                    ? "99+ classes"
                                    : `${item.classCount} ${item.classCount > 1 ? "classes" : "class"}`
                                  : ""}
                              </p>
                              <p>{item?.subjects?.join(" , ")}</p>
                              <ins>${item?.price || ""}/hour</ins>
                            </div>
                          </div>
                        ))}
                    </div>
                    {data?.data?.tutor && data?.data?.tutor?.length > 0 && (
                      <Pagination
                        module={data?.data?.tutor}
                        page={page}
                        onPageChange={onPageChange}
                        totalPages={totalPages}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </ParentLayout>
    </>
  );
}

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { ParentLayout } from "../../../layout/parentLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchFilter from "../common/searchFilter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarIcon from "@mui/icons-material/Star";
import { useFilters } from "../../../context/filterContext";
import {
  useGetFilteredTutorQuery,
  useLazyGetFilteredTutorQuery,
} from "../../../service/tutorApi";
import { TutorDetails } from "../../../types/General";
import { CircularProgress, Skeleton, Typography } from "@mui/material";

type filtersType = {
  experience: string;
  teachingStyle: string;
  verification: string;
  lang: string;
  gender: string;
  time: string;
  curriculam: string;
  rating: string;
  grade: string;
};

export default function ParentSearchResult() {
  const navigate = useNavigate(); // hook for the navigation
  let timeout: NodeJS.Timeout; // Timeout for debouncing fetch requests
  // states
  const [page, setPage] = useState<number>(1);
  const [list, setList] = useState<TutorDetails[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false); // loading state
  const { filters, value, time, selectedSubjects, flag } = useFilters(); // context for the filter state
  const viewRef = useRef<HTMLDivElement | null>(null);
  // API Hooks
  // const { data, isLoading, isSuccess } = useGetFilteredTutorQuery({ page });
  const [getData] = useLazyGetFilteredTutorQuery();

  // function to fetch the data

  const fetchData = async () => {
    try {
      const subjectsFilter = selectedSubjects?.map((item) => ({ name: item }));

      let body = {
        ...(filters?.experience
          ? { totalTeachingExperience: filters.experience }
          : null),
        ...(filters?.curriculam ? { curriculum: filters?.curriculam } : null),
        ...(filters?.teachingStyle
          ? { teachingStyle: filters?.teachingStyle }
          : null),
        ...(filters?.verification
          ? {
              documentVerification:
                Number(filters?.verification) === 1 ? true : false,
            }
          : null),
        ...(filters?.lang ? { teachingLanguage: filters?.lang } : null),
        ...(filters?.gender ? { gender: Number(filters?.gender) } : null),
        ...(filters?.rating ? { avgRating: filters?.rating } : null),
        ...(value[0] > 5 ? { startPrice: value[0] } : null),
        ...(value[1] > 1000 ? { endPrice: value[1] } : null),
        ...(time?.startTime ? { startTime: time?.startTime } : null),
        ...(time?.endTime ? { endTime: time?.endTime } : null),
        ...(filters?.grade ? { classes: filters?.grade } : null),
        ...(selectedSubjects?.length > 0 ? { subjects: subjectsFilter } : null),
        ...(filters?.latitude ? { latitude: filters.latitude } : null),
        ...(filters?.longitude ? { longitude: filters.longitude } : null),
      };
      console.log(body, "bodyyyy");
      const res = await getData({
        page,
        body,
      }).unwrap();
      if (res?.statusCode === 200) {
        const newTutors = res?.data?.tutor || [];
        if (page === 1) {
          setList(newTutors); // Set initial listing data
        } else {
          setList((prev) => [...prev, ...newTutors]); // Append new listings
        }
        if (newTutors.length === 0) {
          setHasMore(false); // No more data to load
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (isSuccess && data) {
  //     const newTutors = data?.data?.tutor || [];

  //     if (page === 1) {
  //       // For the first page, we simply set the list
  //       setList(newTutors);
  //     } else {
  //       // For subsequent pages, we append the new data
  //       setList((prevList) => [...prevList, ...newTutors]);
  //     }

  //     // Update hasMore based on the number of items returned
  //     if (newTutors.length === 0) {
  //       setHasMore(false); // No more data to load
  //     }
  //   }
  // }, [data, isSuccess, page]);

  // Intersection Observer to trigger fetching more data when scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(
      (value) => {
        if (value[0].isIntersecting) {
          if (last) {
            observer.unobserve(last);
            setPage((page) => page + 1); // Increment page to load more data
          }
        }
      },
      { threshold: 0.5 }
    );

    const last = document.querySelector(".tutor_item:last-child"); // Get last property box element
    if (last) {
      observer.observe(last); // Observe last property box for visibility
    }

    return () => {
      if (last) {
        observer.unobserve(last); // Clean up observer
      }
      observer.disconnect(); // Disconnect observer
    };
  }, [list, hasMore]);

  // Effect to fetch data based on filters
  useEffect(() => {
    if (!hasMore) return;
    setIsLoading(true);
    timeout = setTimeout(() => {
      fetchData();
    }, 2000);
    return () => clearTimeout(timeout);
  }, [page, flag]);

  useEffect(() => {
    if (viewRef?.current) {
      viewRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [list]);

  return (
    <>
      <ParentLayout className="role-layout">
        <main className="content">
          <section className="uh_spc pSearchResult_sc">
            <div className="conta_iner v2">
              <SearchFilter setPage={setPage} setHasMore={setHasMore} />
              <div className="searchResult_box" ref={viewRef}>
                <h2>Search Result</h2>
                <div className="gap_p">
                  <div className="lt_s">
                    <div className="tutor_list gap_m">
                      {isLoading && list?.length === 0
                        ? Array.from(new Array(12)).map((item, index) => (
                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              width={210}
                              height={220}
                              key={index}
                              sx={{
                                bgcolor: "#FCFDFFEF",
                                borderRadius: "8px",
                              }}
                            />
                          ))
                        : list?.map((item, index) => (
                            <div className="tutor_item" key={item?._id}>
                              <figure>
                                <img
                                  src={
                                    item?.image || `/static/images/card1.png`
                                  }
                                  alt="Image"
                                />
                                <span className="t_rating">
                                  <StarIcon />{" "}
                                  {item?.avgRating?.toFixed(1) || ""}
                                </span>
                                <span className="t_fav">
                                  {item?.isFav ? (
                                    <FavoriteIcon />
                                  ) : (
                                    <FavoriteBorderIcon />
                                  )}
                                </span>
                              </figure>
                              <div
                                className="tutor_info"
                                onClick={() =>
                                  navigate(`/parent/tutor-detail/${item?._id}`)
                                }
                              >
                                <h2>{item?.name || ""}</h2>
                                <p>
                                  {item?.classCount > 99
                                    ? "99+ classes"
                                    : `${item?.classCount} ${item?.classCount > 1 ? "classes" : "class"}` ||
                                      ""}
                                </p>
                                <p>{item?.subjects?.join(" , ") || ""}</p>
                                <ins>${item?.price || ""}/hour</ins>
                              </div>
                            </div>
                          ))}
                      {isLoading && list.length > 0 && (
                        <div className="bottom-loader">
                          <CircularProgress color="success" />
                        </div>
                      )}
                      {!hasMore && !isLoading && (
                        <div className="bottom-loader">
                          <Typography variant="button">
                            No more tutors found
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="rt_s">
                    <div className="map">
                      <img src={`/static/images/map.jpg`} alt="Image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </ParentLayout>
    </>
  );
}

import TutorCard from "../../../components/tutorCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetPopularTutorListingQuery } from "../../../service/parentDashboard";
import { useEffect, useState } from "react";
import { showError } from "../../../constants/toast";

const QualifiedTutors = () => {



    const { data, isLoading, isError, isSuccess } = useGetPopularTutorListingQuery({ limit: 10, page: 1 });
    // const [data, setData] = useState<any>([]);

    const createData = [{
        name: "Catherine Bell",
        image: "static/images/card1.png"
    }, {
        name: "Michael Smith",
        image: "static/images/card2.png"
    }, {
        name: "Jennifer",
        image: "static/images/card3.png"
    }, {
        name: "Isabella",
        image: "static/images/card4.png"
    }]

    useEffect(() => {

    }, [])
   

    return (
        <section className="home_tutor_sc ub_spc">
            <div className="conta_iner">
                <div className="s_head text_center">
                    <h2>Choose Your Home Tutor from 20,000+ <br /> <mark className="high_light">Qualified Tutors</mark></h2>
                </div>
                <Slider
                    className="tutor_slider"
                    loop={true}
                    infinite={true}
                    slidesToShow={4}
                    slidesToScroll={1}
                    arrows={true}
                    dots={false}
                    responsive={[
                        {
                            breakpoint: 1199,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 575,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: true,
                                centerPadding: "40px"
                            },
                        },
                    ]}
                >
                    {isSuccess && data?.data?.tutor?.length
                        ? [...data?.data?.tutor]?.map((item, index) => (
                            <div key={index}>
                                <TutorCard
                                    classCount={item?.classCount}
                                    subjects={item?.subjects}
                                    price={item?.price}
                                    rating={item?.avgRating}
                                    image={item?.image}
                                    name={item?.name} />
                            </div>
                        ))
                        : undefined}
                </Slider>
            </div>
        </section>
    )
}

export default QualifiedTutors;